<template>
    <div type="text/x-template" id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper" v-on:click='onClick'>
                    <div class="modal-container" :style='"background-color: #" + backgroundColor'>

                        <div>
                            <button type="button" class="close" @click="$emit('close')">×</button>
                            <slot name="header"></slot>
                        </div>

                        <div class="modal-body-1">
                            <slot name="body"></slot>
                        </div>

                        <div>
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        props: {
            backgroundColor: {
                type: String,
                default: () => 'fff'
            }
        },
        methods: {
            onClick: function(event) {
                if (event.target.className == 'modal-wrapper')
                    this.$emit('close')
            }
        }
    }

</script>
<style lang='scss'>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: fit-content;
        min-width: 300px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;

        .close {
            font-size: 50px;
            float: right;
            margin-top: -20px;
            background-color: transparent;
            border-color: transparent;
            color: #f17298;
        }

    }

    .modal-body-1 {
        margin: 15px 0;
        
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>
